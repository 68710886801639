<template>
  <div id="content" class="" style="margin-top:60px;">
    <div class="container--fluid relative">

      <TileMap ref="tileMap" tileset="v1" :zoom="8" max-height="500px;" :mapOptions="mapOptions"  @selected="tileSelected" @mapLoaded="mapLoaded"/>

      <div id="map-hero" class="absolute pa-4 white--text container--fluid" style="min-height:inherit; top:0;bottom:0;right:0;left:0; z-index:2; ">
        <h1 class="display-2 mb-4" style="width:50%;">Challenges<br/> &amp; Events</h1>
        <ul class="check">
          <li>Track Activities</li>
          <li>Join (local) Events</li>
          <li>Conquer the Map!</li>
        </ul>
        <p>

        </p>
        <p class="mb-0">
          <v-btn class="mb-4" color="white" outlined :to="{name: 'register'}">Join for Free!</v-btn>
        </p>
      </div>  
    </div>
    <div v-if="false" id="page-hero" class="pt-6 pb-2 pt-md-12 mb-0 white--text container--fluid">
      <div class="container">
        <v-row>
          <v-col cols="12" sm="12">
            <h1 class="display-2 mb-4">Challenge and Results Platform</h1>
            <p>
              The sodisp platform powers connected and engaging events!
              Our device agnostic platform allows users to connect any fitness tracker and keep using the device or app they already love. All our features are aimed at providing best-in-class user experiences for participants and event organizers.
            </p>
            <p class="mb-0">
              <v-btn class="mr-4 mb-4" color="white" :to="{name:'events'}">Explore events</v-btn>
              <v-btn class="mb-4" color="white" outlined :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
    

    <v-container id="page-upcoming">
      <v-row>
        <v-col>
          <h3 class="title">Active and Upcoming events</h3>
          <EventGrid :items="events" class=""></EventGrid>
          <p class="mb-0 mt-4">
            <v-btn class="mr-4 mb-4" color="white" href="/events">See all</v-btn>
          </p>
        </v-col>
      </v-row>
    </v-container>

    <div class="cta-bar secondary white--text py-6 py-md-12">
      <div class="container">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="subtitle">Our mission:</h2>
            <h1 class="subtitle">"Make it easier and more fun to be active, and keep it up!"</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mr-4 mb-4" color="white" href="/about">About Us</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

  </div>
  
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import eventService from "@/services/eventService";
import TileMap from "@/components/TileMap";
import EventGrid from "@/components/EventGrid.vue";
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

import * as L from 'leaflet';

export default {
  name: "home",
  components: {
    EventGrid,
    TileMap,
    SectionIntegrations,
    SectionCustomers,
  },
  data() {
    return {
      tenant: tenant,
      events: null,
      tile: null,
      siteData: siteData,
      mapOptions: {
        dragging: true,
        tap: false,
        touchZoom: false,
        scrollWheelZoom: false,
        zoomControl: false,
      }
    };
  },
  async mounted() {
    this.events = (await eventService.upcoming(10)).data.data;
  }, 
  methods: {
    async mapLoaded(map) {
      L.control.zoom({
        position: 'topright'
      }).addTo(map);
    },
    async tileSelected(tile) {
      this.tile = tile;
      this.$refs.tileMap.highlightTile(tile);
    },

  },

};
</script>

<style lang="scss" scoped>
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }

  /* CLS improvements:  */
  #page-hero { min-height: 358px;}
  #page-customers { min-height: 340px;}
  #page-integrations { min-height: 436px;}
  #page-upcoming { min-height: 940px;}
  .cta-bar { min-height: 332px;}

  @media (max-width: 500px) {
    #page-hero { min-height: 750px;}
    #page-customers { min-height: 560px;}
    #page-integrations { min-height: 625px;}
    #page-upcoming { min-height: 2844px;}
    .cta-bar { min-height: 332px;}

    #map-hero { clip-path: none !important; position: relative !important; }  
  }
  #map-hero { 
    background: linear-gradient(to bottom, var(--v-header-base), var(--v-gradient-base)); 
    border-bottom: solid 1px var(--v-header-base);
    clip-path: polygon(0 0, 40% 0, 20% 100%, 0 100%);
}

</style> 